// We want to funnel all ChakraProvider instances through this component
// so that we can control the Provider props from a single location.

/* eslint-disable react/forbid-elements */

import { ChakraProvider, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { ReactNode, createContext, useContext } from 'react';
import { theme } from '@ifixit/carpenter-theme';

interface ChakraDefaultProviderProps {
   children: ReactNode;
   theme?: ThemeConfig;
   [key: string]: any;
}

const carpenterTheme = extendTheme(theme);
const ChakraContext = createContext<boolean>(false);

export function ChakraDefaultProvider({ children, ...props }: ChakraDefaultProviderProps) {
   const hasChakraProvider = useContext(ChakraContext);

   if (hasChakraProvider && !props.theme) {
      // If we already have a Chakra provider and aren't passing in a theme (Schematics), just render children so we aren't duplicating providers
      return <>{children}</>;
   }

   return (
      <ChakraContext.Provider value={true}>
         <ChakraProvider {...props} theme={props.theme || carpenterTheme} resetCSS={false}>
            {children}
         </ChakraProvider>
      </ChakraContext.Provider>
   );
}
